<template>
    <div>
        <h5 class="gst-event-experience-package-offers-not-available__title">
            {{ $t( 'title' ) }}
        </h5>
        <h5 class="gst-event-experience-package-offers-not-available__title d-inline-block">
            {{ $t( 'subtitle' ) }}
            <BaseButton
                color="info"
                :ripple="false"
                text
                link
                :data-test-id="$testId('button.back')"
                class="gst-event-experience-package-offers-not-available__back-btn d-inline-block"
                @click="onClickBackButtonDo">
                {{ $t( '_common:buttons.here' ) }}.
            </BaseButton>
        </h5>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';

    export default {
        name: 'EventExperiencePackageOffersSoldOutWarning',
        components: {
            BaseButton
        },
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'messages.eventExperiencePackageSoldOutWarning.soldOut'
        },
        methods: {
            onClickBackButtonDo( ) {
                /**
                 * For the moment that is the quickest solution to reload
                 * There are state in the event page that are been saved even
                 * if go from route to route
                 */
                let r = this.$router.resolve( {
                    name: 'event',
                    params: {
                        id: this.$route.params.id
                    }
                } );
                window.location.assign( r.href );

            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-event-experience-package-offers-not-available__title:nth-child(1) {
        margin-bottom: theme-spacing( 0 ) !important;
    }

    .gst-event-experience-package-offers-not-available__back-btn {
        height: auto !important;
        padding: theme-spacing( 0 ) !important;
        font-size: inherit;
        letter-spacing: inherit;
        min-width: unset !important;
        text-transform: none;
        vertical-align: baseline;
    }

    .gst-event-experience-package-offers-not-available__back-btn:focus::before {
        opacity: 0;
    }
</style>